import { Translation } from "@loveholidays/phrasebook";
import type React from "react";
import type { ReactElement } from "react";

import {
	type ComponentProps,
	DOMInteractionEvent,
	Heading,
	Icon,
	Link,
	Paragraph,
	TrustpilotRating,
	TrustpilotReview,
	type TrustpilotReviewProps,
	useTrackingContext,
} from "@Components";
import { Carousel } from "@Modules";
import { useTranslation } from "@Providers/TranslationProvider";

export interface TrustpilotProps extends ComponentProps {
	displayStars: number;
	score: number;
	scoreText: string;
	numberOfReviews: number;
	reviews: TrustpilotReviewProps[];
	url: string;
	range?: number[];
	LogoComponent?: ReactElement;
}

export const Trustpilot: React.FC<React.PropsWithChildren<TrustpilotProps>> = ({
	className,
	displayStars,
	LogoComponent,
	score,
	scoreText,
	numberOfReviews,
	reviews,
	url,
	range,
}) => {
	const { trackEvent } = useTrackingContext();
	const { namespace, t } = useTranslation();

	return (
		<section data-id="trust-pilot-section" className={className}>
			<Heading
				variant="largebold"
				as="h2"
				sx={{
					justifyContent: "center",
					display: "flex",
					gap: "xs",
				}}
			>
				{scoreText}
				<TrustpilotRating rating={displayStars} />
			</Heading>
			<Heading
				variant="small"
				as="h3"
				sx={{
					textAlign: "center",
					marginTop: "2xs",
				}}
			>
				<Translation
					translationKey="ratedXOutOf5BasedOnXReviewsOnX"
					namespace={namespace}
					params={{
						count: numberOfReviews,
					}}
					components={[
						<strong key={1}>{score}</strong>,
						(text) => (
							<Link
								key={2}
								className="design-system"
								href={url}
								target="_blank"
								rel="noopener noreferrer"
								sx={{
									textDecoration: "underline",
									"&:active, &:visited": {
										color: "textDefault",
									},
								}}
								onClick={() =>
									trackEvent(DOMInteractionEvent.CLICK, "trustpilot-reviews")
								}
							>
								{text}
							</Link>
						),
						LogoComponent || (
							<strong key={3} sx={{ whiteSpace: "nowrap" }}>
								<Icon
									name="Brands/Trustpilot"
									size="20"
									color="iconTrustpilot5star"
								/>
								Trustpilot
							</strong>
						),
					]}
				/>
			</Heading>

			{reviews?.length > 0 && (
				<Carousel
					itemGap={["xs", "xs", "l"]}
					resetScroll
					sx={{
						marginTop: "2xl",
					}}
				>
					{reviews.map((review, idx) => (
						<TrustpilotReview key={idx} {...review} />
					))}
				</Carousel>
			)}

			{range?.length > 0 && (
				<Paragraph
					variant="extrasmall"
					sx={{
						marginTop: "s",
						textAlign: "center",
					}}
				>
					{t("showingRangeOfReview", { range: range.join(" & ") })}
				</Paragraph>
			)}
		</section>
	);
};
